<template>
  <div class="media-custom fd-relative fd-w-full fd-h-full fd-bg-white fd-px-6 fd-py-6">
    <div class="fd-w-full fd-flex fd-items-center fd-justify-start fd-mb-4">
      <span class="fd-text-lg fd-font-bold fd-text-theme-2">{{ $t('phases.requiredForm') }}</span>
    </div>
    <div class="fd-w-full fd-grid fd-grid-cols-4 fd-gap-5">
      <div
        v-for="(file, index) in requiredForm"
        :key="index"
        class="fd-w-full fd-rounded-lg fd-border fd-border-theme-7 fd-cursor-pointer fd-overflow-hidden"
      >
        <div class="fd-flex fd-items-center fd-justify-center fd-py-12" style="background-color: #F8F8F8">
          <SvgLoader :name="'pdfIcon'" />
        </div>
        <div class="fd-p-5 fd-pb-2 fd-pt-2 fd-flex fd-items-center fd-justify-between">
          <span class="fd-text-theme-6 fd-text-base fd-font-medium">{{ file.name }}</span>
          <span class="fd-text-theme-10 fd-text-sm fd-font-normal">{{ file.size }}</span>
        </div>
      </div>
    </div>
    <div class="fd-w-full fd-absolute fd-left-0 fd-right-0 fd-bottom-0 fd-mb-4 fd-px-4">
      <DragAndDropUploader  :postAction="uploadApi" @files="setFiles" :name="'form'"/>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import DragAndDropUploader from "@/views/components/global/DragAndDropUploader";
import BaseService from "@/http/baseService";

export default {
  name: "RequiredForm",
  components: {DragAndDropUploader},
  data() {
    return {
      files: [],
      baseUrl: '',
      uploadApi: this.baseUrl + `/phases/${this.$route.params.id}/media`
    }
  },
  created() {
    this.baseUrl = BaseService.getInstance().getBaseUrl()
    this.$store.dispatch('phase/getRequiredForm', this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      requiredForm: 'phase/requiredForm'
    })
  },
  methods: {
    setFiles(files) {
      this.files = files
    }
  }
}
</script>

<style scoped>

</style>